import { useEffect, useRef, useState } from 'react';
import { ThemeUIStyleObject, Container } from 'theme-ui';
import BundleAddToCart from './AddToCart';
import PDPDetails from '~/components/Generic/PDPWrapper/Details';
import PDPContent from '~/components/Generic/PDPWrapper/Content';
import { ProductMap } from '~/types';

type Props = {
  bundle: Queries.BundlePageFragment | Queries.KitPageFragmentFragment;
  notifications: Queries.NotificationFragmentFragment[];
  collectionName: string;
  forwardSx?: ThemeUIStyleObject;
};

export const isBundle = (
  bundle: Queries.BundlePageFragment | Queries.KitPageFragmentFragment
): bundle is Queries.BundlePageFragment => {
  return bundle.__typename === 'ContentfulBundle';
};

const BundleDetails = ({ bundle, notifications, collectionName, forwardSx }: Props) => {
  const OOSNotify = useRef<Queries.NotificationFragmentFragment | null>(null);
  const comingSoonNotify = useRef<Queries.NotificationFragmentFragment | null>(null);
  const discontinuedNotify = useRef<Queries.NotificationFragmentFragment | null>(null);
  const freeShipping = useRef<Queries.NotificationFragmentFragment | null>(null);

  const [bundleAvailable, setBundleAvailable] = useState(true);

  const [productsMap, setProductsMap] = useState<ProductMap>({});

  useEffect(() => {
    const setSections = () => {
      notifications.forEach((s) => {
        s?.slug === 'notification-oos-pdp' && s && (OOSNotify.current = s);
        s?.slug === 'notification-comingsoon-pdp' && s && (comingSoonNotify.current = s);
        s?.slug === 'notification-discontinued-pdp' && s && (discontinuedNotify.current = s);
        s?.slug === 'pdp-free-shipping' && s && (freeShipping.current = s);
      });
    };
    setSections();
  }, [notifications]);

  return (
    <Container
      variant={'fullwidth'}
      as="section"
      sx={{
        mx: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 'var(--vertical-spacing)',
        ...forwardSx,
      }}
    >
      <PDPDetails
        slug={bundle.slug}
        price={bundle.price}
        regularPrice={bundle.regularPrice}
        title={bundle.name}
        subtitle={bundle.shortDescription}
        isKit
      />

      <BundleAddToCart
        setProductsMap={setProductsMap}
        productsMap={productsMap}
        comingSoonNotify={comingSoonNotify}
        freeShipping={freeShipping}
        OOSNotify={OOSNotify}
        bundleAvailable={bundleAvailable}
        setBundleAvailable={setBundleAvailable}
        collectionName={collectionName}
        bundle={bundle}
        forwardSx={{ paddingTop: [null, 0] }}
      />

      <PDPContent item={bundle} />
    </Container>
  );
};

export default BundleDetails;
