import React from 'react';
import { PageProps, graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import BundlePage from '~/components/Bundle/Page';

type DataProps = {
  kit: Queries.KitPageFragmentFragment;
  notifications: {
    pdpNotifications: Queries.NotificationFragmentFragment[];
  };
  productCollection: { nodes: Queries.ProductCollectionFragment[] };
};

const KitTemplate = ({ data }: PageProps<DataProps>) => {
  const { kit, notifications, productCollection } = data;
  const { collection } = kit;
  return (
    <Layout>
      <Metadata
        // @ts-ignore
        title={kit.metaTitle ?? kit.name}
        description={kit.metaDescription ?? kit.shortDescription}
        image={kit?.mainImage?.file?.url}
      />
      <BundlePage
        bundle={kit}
        notifications={notifications.pdpNotifications}
        collection={collection}
        productCollection={productCollection?.nodes}
      />
    </Layout>
  );
};

export const query = graphql`
  query KitPage($slug: String, $locale: String) {
    kit: contentfulKit(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...KitPageFragment
    }

    notifications: contentfulSiteMetadata(slug: { eq: "site-metadata" }) {
      pdpNotifications {
        ...NotificationFragment
      }
    }
    productCollection: allContentfulProduct(
      filter: {
        collection: { elemMatch: { kits: { elemMatch: { slug: { eq: $slug } } } } }
        hideOnShopAll: { ne: true }
      }
    ) {
      nodes {
        ...ProductCollection
      }
    }
  }
`;

export default KitTemplate;
